// fonts
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

// bootstrap & variables
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import "variables";
@import "utilities";
@import "user";
@import "~bootstrap/scss/bootstrap";

// components
@import "components/_index";